import { SkeletonTheme } from 'react-loading-skeleton';

import type { FunctionComponent } from 'react';
import type { TypeCustomSkeletonThemeProps } from './types';

/**
 * CustomSkeletonTheme
 */
export const CustomSkeletonTheme: FunctionComponent<
  TypeCustomSkeletonThemeProps
> = ({ children }: TypeCustomSkeletonThemeProps) => (
  <SkeletonTheme
    baseColor="#F8F7F4" // Color base
    highlightColor="#F4F0EB" // Color de las olas
    inline
    borderRadius={0}
  >
    {children}
  </SkeletonTheme>
);

CustomSkeletonTheme.displayName = 'CustomSkeletonTheme';
