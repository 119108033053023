import { useCallback, useEffect, useRef } from 'react';
import { debounce } from 'lodash-es';

import type { RefObject } from 'react';

type TypeUseHeaderSticky = {
  headerRef: RefObject<HTMLElement>;
  cintaRef: RefObject<HTMLDivElement>;
  threshold?: number;
  delay?: number;
  withOutSticky: boolean;
};

// TODO: It works for now but can be improved
export const useHeaderSticky = ({
  headerRef,
  cintaRef,
  withOutSticky,
  delay = 500,
  threshold = 5,
}: TypeUseHeaderSticky) => {
  const prevPosition = useRef(0);
  const running = useRef(false);

  const checkIfScrollStoped = useCallback(
    debounce(() => {
      if (headerRef.current && headerRef.current.style) {
        headerRef.current.style.top = '0px';
      }
    }, delay),
    [delay],
  );

  const handleScroll = useCallback(() => {
    if (window.scrollY === 0 && cintaRef.current) {
      cintaRef.current.classList.remove('hidden');
      prevPosition.current = window.scrollY;
    }

    if (Math.abs(prevPosition.current - window.scrollY) >= threshold) {
      if (!headerRef.current || !headerRef.current.style) return;

      headerRef.current.style.top =
        prevPosition.current > window.scrollY
          ? '0px'
          : `-${headerRef.current.clientHeight}px`;

      prevPosition.current = window.scrollY;
    }

    checkIfScrollStoped();
  }, [checkIfScrollStoped, cintaRef, headerRef, threshold]);

  const handleObserver = useCallback(
    ([entry]: IntersectionObserverEntry[]) => {
      if (cintaRef.current && entry && entry.intersectionRatio === 0) {
        cintaRef.current.classList.add('hidden');
      }
    },
    [cintaRef],
  );

  useEffect(() => {
    if (withOutSticky) {
      cintaRef.current?.classList.remove('hidden');
      return;
    }

    prevPosition.current = window.scrollY;

    const observer = new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin: '-0.1px',
      threshold: 0,
    });

    if (headerRef.current) {
      observer.observe(headerRef.current);
    }

    const handle = () => {
      if (!running.current) {
        running.current = true;
        requestAnimationFrame(handleScroll);
      }

      running.current = false;
    };

    window.addEventListener('scroll', handle, {
      passive: true,
    });
    return () => {
      window.removeEventListener('scroll', handle);
      observer.disconnect();
    };
  }, [handleObserver, handleScroll, headerRef, withOutSticky]);
};
