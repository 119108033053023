import { OrganizationJsonLd } from 'next-seo';

import { SymbolCart } from '@/icons';
import { Footer, Header, Snackbar } from '@/organisms';

import { snackbarTransformer } from '@/lib/graphql/transformers';
import { routes } from '@/lib/routes';
import { getRealURL } from '@/lib/utils';
import Layout from './Layout';

import type { TypeSnackbarGraphQL } from '@/lib/graphql/types';
import type { PropsWithChildren } from '@/types';

type TypeLayout = PropsWithChildren<{
  snackbarActive?: string | null;
  snackbar?: TypeSnackbarGraphQL;
}>;

export const getBaseLayout = ({
  children,
  snackbarActive,
  snackbar,
  ...props
}: any) => (
  <Layout {...props}>
    {/** TODO: conectar con ACF */}
    <OrganizationJsonLd
      type="Organization"
      name="Bigcrafters"
      url={process.env.NEXT_PUBLIC_FRONT_URL ?? ''}
      logo={props?.logo?.sourceUrl}
      description="Plataforma de comercio electrónico orientada a la venta de productos artesanales, así como a impulsar la presencia digital de pequeños productores artesanos."
      sameAs={props?.socialNetworks?.map(
        (social: { icon: string; link: string }) => social?.link,
      )}
      knowsAbout={['productos artesanos']}
      contactPoint={[
        {
          contactType: 'customer service',
          telephone: '+34 900 190 006',
          url: getRealURL(routes.contact),
          availableLanguage: 'Spanish',
        },
      ]}
    />

    <BaseLayout snackbar={snackbar} snackbarActive={snackbarActive}>
      {children}
    </BaseLayout>
  </Layout>
);

const BaseLayout = ({ children, snackbarActive, snackbar }: TypeLayout) => (
  <>
    <SymbolCart />

    <Header />

    <main className="grow">
      {snackbarActive && snackbar && (
        <Snackbar {...snackbarTransformer(snackbar)} />
      )}
      {children}
    </main>

    <Footer />
  </>
);

export default BaseLayout;
