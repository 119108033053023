import React, { Fragment, useContext, useState } from 'react';
import { Dialog, Disclosure, Transition } from '@headlessui/react';
import { oneLine } from 'common-tags';

import { Add, ChevronDown, Less, Menu } from '@/icons';
import { NextLink } from '@/atoms';
import { Button } from '@/molecules';

import { InitPropsProvider } from '@/components/providers/initPropsProvider';
import { useRouteChangeStart } from '@/lib/hooks';
import { routes } from '@/lib/routes';
import { removeBaseURL } from '@/lib/utils';
import FooterMobile from './Footer';
import { HeaderMobile } from './Header/Header';

/**
 * MobileMenu
 */
export const MobileMenu = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [subMenuOpen, setSubmenuOpen] = useState(-1);
  const {
    options: { ajustesGenerales },
    menus: {
      primaryMenu: { menus },
    },
  } = useContext(InitPropsProvider);

  useRouteChangeStart(() => setMenuOpen(false));

  return (
    <>
      <button
        type="button"
        className="lg:hidden"
        onClick={() => {
          setMenuOpen(true);
        }}
      >
        <span className="sr-only">Open menu</span>
        <Menu className="h-6 w-6" aria-hidden="true" />
      </button>

      <Transition.Root show={menuOpen} as={Fragment} unmount={false}>
        <Dialog
          as="div"
          className="fixed inset-0 z-40 flex lg:hidden"
          onClose={setMenuOpen}
          unmount={false}
          open={menuOpen}
        >
          <Transition.Child
            unmount={false}
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <Transition.Child
            unmount={false}
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div
              className={oneLine`relative z-[100] flex h-full w-full flex-col bg-white px-4 py-6 
              ${subMenuOpen === -1 ? 'overflow-y-auto' : ''}`}
            >
              <HeaderMobile setOpen={setMenuOpen} />
              <div className="flex flex-grow flex-col py-4">
                {menus?.map((menu: any, index: number) =>
                  !menu.column && menu?.parentMenuLink?.url ? (
                    <NextLink
                      href={menu?.parentMenuLink?.url ?? '/'}
                      key={index}
                    >
                      <a className="u-body u-body--l py-4 normal-case">
                        {menu.parentMenu}
                      </a>
                    </NextLink>
                  ) : (
                    <React.Fragment key={index}>
                      <div
                        className="flex w-full cursor-pointer justify-between py-4"
                        onClick={() => setSubmenuOpen(index)}
                      >
                        <p className="u-body u-body--l normal-case">
                          {menu.parentMenu}
                        </p>

                        <ChevronDown
                          className="-rotate-90"
                          color="var(--tw-color-secondary-500)"
                        />
                      </div>

                      {menu.column && (
                        <Transition
                          show={index === subMenuOpen}
                          as={Fragment}
                          enter="transition ease-in-out duration-300 transform"
                          enterFrom="-translate-x-full"
                          enterTo="translate-x-0"
                          leave="transition ease-in-out duration-300 transform"
                          leaveFrom="translate-x-0"
                          leaveTo="-translate-x-full"
                        >
                          <div className="absolute left-0 top-0 z-[100] flex h-full w-full flex-col overflow-y-auto bg-white px-4 py-6">
                            <HeaderMobile
                              setOpen={() => {
                                setMenuOpen(false);
                                setSubmenuOpen(-1);
                              }}
                            />

                            <div
                              role="button"
                              onClick={() => setSubmenuOpen(-1)}
                              className="flex items-end justify-start gap-6 border-b border-b-primary-100 py-4"
                            >
                              <ChevronDown
                                className="relative top-[1px] rotate-90"
                                color="var(--tw-color-secondary-500)"
                                height={24}
                                width={24}
                              />

                              <p className="u-body u-body--l normal-case">
                                {menu.parentMenu}
                              </p>
                            </div>

                            <div className="flex flex-grow flex-col items-start py-4">
                              {menu.column.map((column: any) =>
                                column.bloque.map(
                                  (bloque: any, index: number) =>
                                    bloque.subMenuMobile && bloque.menuPages ? (
                                      <Disclosure key={index}>
                                        {({ open }) => (
                                          <>
                                            <Disclosure.Button
                                              as="div"
                                              className="flex w-full items-center justify-between py-4"
                                            >
                                              <p className="u-body u-body--m normal-case text-primary-600">
                                                {bloque.title}
                                              </p>
                                              {open ? (
                                                <Less
                                                  color="#F68217"
                                                  width={24}
                                                  height={24}
                                                />
                                              ) : (
                                                <Add
                                                  color="#F68217"
                                                  width={24}
                                                  height={24}
                                                />
                                              )}
                                            </Disclosure.Button>

                                            <Disclosure.Panel
                                              unmount={false}
                                              className="flex w-full flex-col px-4 pb-4"
                                              as="ul"
                                            >
                                              {bloque?.lastLinkTitle &&
                                                bloque?.lastLinkTitleUrl &&
                                                bloque?.lastLinkTitleUrl
                                                  ?.url && (
                                                  <NextLink
                                                    href={
                                                      removeBaseURL(
                                                        bloque.lastLinkTitleUrl
                                                          .url,
                                                      ) ?? '/'
                                                    }
                                                  >
                                                    <a className="u-body u-body--s my-0 pb-4 normal-case text-primary-900 hover:text-primary-500">
                                                      {bloque.lastLinkTitle}
                                                    </a>
                                                  </NextLink>
                                                )}
                                              {bloque?.menuPages.map(
                                                (
                                                  menuPage: any,
                                                  index: number,
                                                ) => (
                                                  <li
                                                    key={index}
                                                    className="w-full py-4"
                                                  >
                                                    <NextLink
                                                      href={
                                                        menuPage?.pageUrl
                                                          ?.url ?? '/'
                                                      }
                                                    >
                                                      <a className="u-body u-body--m normal-case text-primary-400">
                                                        {menuPage.pageTitle}
                                                      </a>
                                                    </NextLink>
                                                  </li>
                                                ),
                                              )}
                                            </Disclosure.Panel>
                                          </>
                                        )}
                                      </Disclosure>
                                    ) : (
                                      <NextLink
                                        key={index}
                                        href={bloque?.page?.url ?? '/'}
                                      >
                                        <a className="u-body u-body--m w-full py-4 normal-case text-primary-600">
                                          {bloque.title}
                                        </a>
                                      </NextLink>
                                    ),
                                ),
                              )}
                            </div>
                            <FooterMobile
                              socialNetworks={ajustesGenerales.socialNetworks}
                              button={
                                menu?.parentMenuLink?.url && (
                                  <NextLink
                                    href={menu.parentMenuLink.url}
                                    passHref
                                  >
                                    <Button
                                      label={menu.parentMenu}
                                      size="large-full"
                                      style="primary"
                                      as="a"
                                    />
                                  </NextLink>
                                )
                              }
                            />
                          </div>
                        </Transition>
                      )}
                    </React.Fragment>
                  ),
                )}
              </div>

              <FooterMobile
                socialNetworks={ajustesGenerales.socialNetworks}
                button={
                  <NextLink href={routes.joinBigCrafters} passHref>
                    <Button
                      label="únete a bigcrafters®"
                      size="large-full"
                      style="secondary"
                      as="a"
                    />
                  </NextLink>
                }
              />
            </div>
          </Transition.Child>
        </Dialog>
      </Transition.Root>
    </>
  );
};
