import { useEffect, useState } from 'react';
import { signOut, useSession } from 'next-auth/react';
import { useRouter } from 'next/router';

import { useCart, useCartDispatch, useToastDispatch } from '@/lib/hooks';
import {
  gtmPush,
  isCartRoute,
  isEmptyObject,
  isVariable,
  normalizeCartItems,
  normalizeItem,
  priceToNumber,
  SumCurrency,
} from '@/lib/utils';
import { DEFAULT_TOAST_OPTIONS_WARNING } from '../providers';

const urlWithoutParams = (url: string) => url?.split('?')?.at(0);
const areDistinctUrls = (url1: string, url2: string) =>
  urlWithoutParams(url1) !== urlWithoutParams(url2);

// TODO: Añadir tipos
export default function Layout({ children, ...props }: any): JSX.Element {
  const [lastView, setLastView]: any = useState(null);
  const router = useRouter();
  const session: any = useSession();
  const { getCart } = useCartDispatch();

  useEffect(() => {
    if (session && session.data?.user?.UID && !window.userGigyaId)
      window.userGigyaId = session.data?.user?.UID;

    if (session?.data?.error === 'JWT Expired') signOut();

    if (session.status === 'authenticated') {
      getCart();
    }
  }, [session]);

  const { cart, cartError, loading: isCartLoading } = useCart();

  const { toast } = useToastDispatch();

  const getmPageView = (): void => {
    const url = router.asPath;
    let pageName: string | undefined = undefined;

    // get page name
    if (url === '/' || router.pathname === '/') {
      pageName = 'home';
    } else if (url !== '/') {
      pageName = url.split('/').pop()?.split('?').at(0);
    }

    if (!isCartLoading) {
      const categories: any[] = [];
      window.oct8ne.customData.importeCesta = cart.contentsTotal;
      cart.contents.products.forEach((item) =>
        item.product.productCategories.edges.forEach((category: any) => {
          if (!categories.includes(category.node.name)) {
            categories.push(category.node.name);
          }
        }),
      );
      window.oct8ne.customData.categoriasProductosCesta = categories;
    }

    // check if session && cart are loaded
    if (session.status !== 'loading' && !isCartLoading) {
      const isProductPage: boolean = url.includes('/producto/');
      // view product page event
      if (areDistinctUrls(lastView, router.asPath) && isProductPage) {
        if (
          window.oct8ne &&
          !router.pathname.includes('404') &&
          props.breadcrumbs &&
          props.type &&
          props.variations &&
          props.sellerInfo
        ) {
          window.oct8ne.customData.page = url;
          window.oct8ne.customData.productCategory =
            props.breadcrumbs[props.breadcrumbs.length - 2].url;
          window.oct8ne.customData.productPrice = props.price;
          window.oct8ne.customData.productSeller = isVariable(props.type)
            ? props.variations.edges[0].node.sellerInfo.name
            : props.sellerInfo.name;
        }
        gtmPush({
          event: 'view_item',
          ecommerce: {
            items: [normalizeItem(props, null, null, 0)],
          },
        });

        setLastView(router.asPath);
      }

      // view cart event
      if (
        areDistinctUrls(lastView, router.asPath) &&
        isCartRoute(url) &&
        !isCartLoading
      ) {
        gtmPush({
          event: 'view_cart',
          ecommerce: {
            currency: 'EUR',
            discount: priceToNumber(
              SumCurrency(cart.discountTotal, cart.discountTax),
            ),
            coupon: cart.appliedCoupons,
            value: priceToNumber(cart.total),
            items: normalizeCartItems(cart),
          },
        });

        setLastView(router.asPath);
      }

      // view rest of pages && !product page || cart
      if (areDistinctUrls(lastView, router.asPath)) {
        if (window.oct8ne) {
          window.oct8ne.customData.page = url;
          if (!isProductPage) {
            window.oct8ne.customData.productCategory = null;
            window.oct8ne.customData.productPrice = null;
            window.oct8ne.customData.productSeller = null;
          }
          //
        }

        gtmPush({
          event: 'page-view', // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          gigya_id: session.data?.user.UID || null, // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          user_id: session.data?.user.customerId || null,
          page: pageName,
          url,
        });
        setLastView(router.asPath);
      }
    }
  };

  useEffect(() => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    getmPageView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  useEffect(() => {
    if (!isEmptyObject(cartError) && cartError.message !== '') {
      toast(cartError.message ?? 'Oops! Algo ha salido mal.', {
        ...DEFAULT_TOAST_OPTIONS_WARNING,
        ...(cartError.message ===
          'No puedes añadir este producto porque superarías el límite de peso.' && {
          link: {
            type: 'url',
            label: 'Leer más',
            url: '/bases-legales#entregas',
          },
        }),
      });
    }
  }, [cartError]);

  useEffect(() => {
    getCart();
    document.addEventListener('visibilitychange', (event) => {
      if (!event?.target) return;
      if (document.visibilityState === 'visible') getCart();
    });
    return () => {
      document.removeEventListener('visibilitychange', () => getCart());
    };
  }, []);

  return <>{children}</>;
}
