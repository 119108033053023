import { defaultProps } from './types';

import type { IconType } from './types';

/**
 * Icono Twitter
 */
export const Twitter = ({
  className,
  width = defaultProps.width,
  height = defaultProps.height,
  color = defaultProps.color,
}: IconType): JSX.Element => (
  <svg
    {...(className && { className })}
    width={width}
    height={height}
    viewBox="0 0 21 18"
    fill="none"
  >
    <path
      d="M14.3 2.54999C13.54 2.54987 12.8103 2.84811 12.2679 3.38056C11.7256 3.913 11.4139 4.63709 11.4 5.39699L11.372 6.97199C11.3704 7.05656 11.3509 7.13982 11.3148 7.21632C11.2787 7.29281 11.2269 7.36082 11.1627 7.41587C11.0985 7.47092 11.0233 7.51178 10.9422 7.53575C10.8611 7.55973 10.7758 7.56629 10.692 7.55499L9.13102 7.34299C7.07702 7.06299 5.10902 6.11699 3.22102 4.54399C2.62302 7.85399 3.79102 10.147 6.60402 11.916L8.35102 13.014C8.43403 13.0662 8.50299 13.1379 8.55187 13.2228C8.60075 13.3078 8.62806 13.4035 8.63141 13.5015C8.63477 13.5995 8.61407 13.6968 8.57111 13.7849C8.52816 13.873 8.46426 13.9493 8.38502 14.007L6.79302 15.17C7.74002 15.229 8.63902 15.187 9.38502 15.039C14.103 14.097 17.24 10.547 17.24 4.69099C17.24 4.21299 16.228 2.54999 14.3 2.54999V2.54999ZM9.40002 5.35999C9.41747 4.39604 9.71891 3.45866 10.2666 2.66521C10.8142 1.87177 11.5838 1.25751 12.4789 0.899359C13.374 0.541207 14.3549 0.455072 15.2988 0.65174C16.2426 0.848407 17.1074 1.31914 17.785 2.00499C18.496 1.99999 19.101 2.17999 20.454 1.35999C20.119 2.99999 19.954 3.71199 19.24 4.69099C19.24 12.333 14.543 16.049 9.77702 17C6.50902 17.652 1.75702 16.581 0.39502 15.159C1.08902 15.105 3.90902 14.802 5.53902 13.609C4.16002 12.7 -1.32898 9.46999 2.27802 0.785992C3.97102 2.76299 5.68802 4.10899 7.42802 4.82299C8.58602 5.29799 8.87002 5.28799 9.40102 5.36099L9.40002 5.35999Z"
      fill={color}
    />
  </svg>
);
