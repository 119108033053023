import Skeleton from 'react-loading-skeleton';
import { oneLine } from 'common-tags';

import { CustomSkeletonTheme } from '@/atoms';

import { priceToNumber, toCurrency } from '@/lib/utils';
import styles from './Price.module.css';

import type { FunctionComponent } from 'react';
import type { TypePriceProps } from './types';

/**
 * Price
 */
export const Price: FunctionComponent<TypePriceProps> = ({
  className = '',
  isDescription = false,
  regularPrice,
  price,
  quantity,
  loading,
  variant = 'vertical',
}: TypePriceProps) => {
  const hasDiscount =
    price && regularPrice && toCurrency(price) !== toCurrency(regularPrice);

  return (
    <CustomSkeletonTheme>
      <div
        className={oneLine`
          ${styles['c-price']}
          ${isDescription ? styles['c-price--description'] : ''}
          ${variant === 'horizontal' ? 'flex-row-reverse gap-2' : 'flex-col'}
          ${className}
        `}
        data-playwright="price"
      >
        {hasDiscount && regularPrice && (
          <p className={styles['c-price__regular-price']}>
            {toCurrency(regularPrice)}
          </p>
        )}

        <p
          className={oneLine`
              ${styles['c-price__price']}
              ${hasDiscount ? styles['c-price__price-sale'] : ''}
            `}
        >
          {loading || !price ? (
            <Skeleton containerClassName="min-w-[40px] block" />
          ) : (
            toCurrency(price)
          )}
        </p>

        {quantity && price && quantity !== 0 && quantity !== 1 && (
          <p className="u-font-card u-font-card--subtitle text-typo-secondary">
            Unidad: {toCurrency(priceToNumber(price) / quantity)}
          </p>
        )}
      </div>
    </CustomSkeletonTheme>
  );
};
