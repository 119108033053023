import * as Icons from '@/icons';

import { removeBaseURL } from '@/lib/utils';

import type { TypeSnackbarGraphQL } from '@/lib/graphql/types';
import type { TypeSnackbarProps } from '@/organisms/snackbar';

export const snackbarTransformer = (
  snackbar: TypeSnackbarGraphQL,
): TypeSnackbarProps => ({
  type: snackbar.snackbarType,
  message: snackbar.snackbarMessage,
  ...(snackbar.snackbarMobileMessage
    ? {
        mobileMessage: snackbar.snackbarMobileMessage,
      }
    : {}),
  backgroundColor: snackbar.snackbarBackgroundColor,
  textColor: snackbar.snackbarTextColor,

  ...(snackbar.snackbarType === 'simple' && {
    ...(snackbar.snackbarIcon
      ? {
          Icon: Icons[snackbar.snackbarIcon.icon as keyof typeof Icons],
        }
      : {}),
    ...(snackbar.snackbarCta.label && snackbar.snackbarCta.link.url
      ? {
          button: {
            ...snackbar.snackbarCta,
            link: {
              url: removeBaseURL(snackbar.snackbarCta.link.url),
            },
          },
        }
      : {}),
  }),

  ...(snackbar.snackbarType === 'countdown' && {
    ...(snackbar.snackbarCode && {
      code: snackbar.snackbarCode,
    }),
    ...(snackbar.snackabrCountdown && {
      countdown: snackbar.snackabrCountdown,
    }),
  }),
});
