import { Fragment, useContext, useEffect, useState } from 'react';

import { Info } from '@/icons';
import { NextLink } from '@/atoms';
import {
  CartLine,
  CartLineFooter,
  CartLineHeader,
  CartShippingHeader,
  Modal,
} from '@/molecules';

import { InitPropsProvider } from '@/components/providers/initPropsProvider';
import { useCartDispatch } from '@/lib/hooks';
import { routes } from '@/lib/routes';
import {
  debug,
  imageWithFallback,
  isOperator,
  priceToNumber,
  removeBaseURL,
  toCurrency,
} from '@/lib/utils';
import FallBackIMG from '@/assets/images/fallback-product-card.jpg';
import { repeat } from '@/stories/utils';

import type { FunctionComponent } from 'react';
import type { TypeCartLinesProps } from './types';

// TODO: Limpiar codigo, es muy confuso ahora mismo
/**
 * CartLines
 */
export const CartLines: FunctionComponent<TypeCartLinesProps> = ({
  className = '',
  loading,
  cart,
  editable = true,
  type = 'cart',
  hookSeller,
  hookLineHeader,
}: TypeCartLinesProps) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const propsProvider = useContext(InitPropsProvider);
  const bcOptions = propsProvider?.options?.ajustesGenerales;

  const { update, remove } = useCartDispatch();

  useEffect(
    () => () => {
      setIsUpdating(false);
    },
    [],
  );

  return (
    <>
      <Modal open={showModal} setOpen={setShowModal} unmount>
        <h2 className="mb-4 md:mb-6">GASTOS DE ENVÍO</h2>

        <div className="prose">
          <p>
            El coste de envío es de 5 € IVA incluido. El coste del envío es
            gratuito para pedidos superiores a 50 €*.
          </p>

          <p>
            Envío solo a España peninsular. Los pedidos tendrán un límite de
            peso de {bcOptions.weightMaxCart} kg. Para pedidos de un volumen
            superior póngase en
            <NextLink href={routes.contact}>
              <a className="text-secondary-500">
                {' '}
                contacto con nuestro servicio de Atención al Cliente.
              </a>
            </NextLink>
          </p>

          <p>* Límite sujeto a promociones.</p>
        </div>
      </Modal>

      <div
        className={`flex flex-col gap-4 md:gap-6 ${className}`}
        data-playwright="cartLines"
      >
        {!loading && cart
          ? cart?.map((seller) => (
              <div className="flex flex-col gap-4" key={seller.id}>
                <div className="grid w-full grid-cols-1 gap-px">
                  <CartShippingHeader
                    title={`Vendido por ${seller.name}`}
                    {...(type === 'order' &&
                      'orderNumber' in seller && {
                        description: `Nº ${
                          isOperator(seller.name)
                            ? `BC-${seller.orderNumber}`
                            : `WPT-${seller.orderNumber}-A`
                        }`,
                      })}
                  />

                  {seller.orders instanceof Array &&
                    seller.orders.map((order, index) => (
                      <Fragment key={index}>
                        <CartLineHeader
                          title={order.shippingEstimateDate.date}
                          {...(type === 'order' &&
                            'status' in seller &&
                            'tracking' in order && {
                              status: seller.status,
                            })}
                          {...(hookLineHeader && {
                            buttons:
                              typeof hookLineHeader === 'function'
                                ? hookLineHeader(seller, order)
                                : hookLineHeader,
                          })}
                        />

                        {order.offers.map((offer) => (
                          <CartLine
                            key={offer.id}
                            editable
                            disabled={isUpdating}
                            image={imageWithFallback(
                              offer.product?.image?.sourceUrl ?? '',
                              offer.product?.image?.altText ?? '',
                              FallBackIMG.src,
                            )}
                            name={offer.product.name as string}
                            {...('bundleItems' in offer && {
                              bundleItems: offer.bundleItems,
                            })}
                            {...('description' in offer.product &&
                              offer.product.description && {
                                description: offer.product.description,
                              })}
                            {...('remainingRefundQuantity' in offer
                              ? {
                                  quantity:
                                    offer.remainingRefundQuantity ??
                                    offer.quantity,
                                }
                              : {
                                  quantity: offer.quantity,
                                })}
                            {...(editable &&
                              'key' in offer && {
                                onChange: async (value: number) => {
                                  setIsUpdating(true);
                                  await update(offer.key, value).catch(
                                    (error) => debug(error),
                                  );
                                  setIsUpdating(false);
                                },
                                onRemove: async () => {
                                  setIsUpdating(true);
                                  await remove(offer.key).catch((error) =>
                                    debug(error),
                                  );
                                  setIsUpdating(false);
                                },
                              })}
                            price={
                              'remainingRefund' in offer &&
                              offer.remainingRefund
                                ? toCurrency(offer?.remainingRefund)
                                : toCurrency(offer.total)
                            }
                            {...('regularPrice' in offer.product &&
                              offer.product.regularPrice && {
                                regularPrice: toCurrency(
                                  priceToNumber(offer.product.regularPrice) *
                                    offer.quantity,
                                ),
                              })}
                            link={removeBaseURL(offer.product.link)}
                            className="bg-bg-secondary"
                          />
                        ))}
                      </Fragment>
                    ))}

                  {type !== 'order' && (
                    <CartLineFooter
                      className="bg-bg-secondary"
                      price={seller.shippingTotal}
                      {...('minFreeAmount' in seller &&
                        seller.minFreeAmount && {
                          howMuchLeftForFreeShipping:
                            seller.minFreeAmount - seller.total,
                        })}
                      {...(isOperator(seller.name) && {
                        shippingIcon: (
                          <button onClick={() => setShowModal(true)}>
                            <Info width={20} height={20} />
                          </button>
                        ),
                      })}
                      {...('path' in seller && {
                        shippingLink: seller.path,
                      })}
                    />
                  )}

                  {typeof hookSeller === 'function'
                    ? hookSeller(seller)
                    : hookSeller}
                </div>
              </div>
            ))
          : repeat(
              2,
              <div className="flex flex-col gap-4">
                <div className="grid w-full grid-cols-1 gap-px bg-bg-secondary">
                  <CartLineHeader loading />
                  <CartLine loading />
                </div>
              </div>,
            )}
      </div>
    </>
  );
};

CartLines.displayName = 'CartLines';
