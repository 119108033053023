import { oneLine } from 'common-tags';

import { Close } from '@/icons';
import { NextLink } from '@/atoms';

import { useToastDispatch } from '@/lib/hooks';
import { removeBaseURL } from '@/lib/utils';

import type { FunctionComponent } from 'react';
import type { TypeToastProps } from './types';

/**
 * Toast
 */
const Toast: FunctionComponent<TypeToastProps> = ({
  backgroundColor,
  id,
  message,
  visible,
  Icon,
  className = '',
  link,
}) => {
  const { closeToast } = useToastDispatch();

  return (
    <div
      className={oneLine`
        flex w-full flex-row justify-center gap-2 px-4 py-3 will-change-transform 
        ${visible ? 'animate-toast-out' : 'animate-toast-in'} 
        ${className}
      `}
      style={{
        backgroundColor,
      }}
    >
      <div className="flex flex-row items-center gap-2">
        {Icon && (
          <Icon width={19} height={19} color="#FFFFFF" className="shrink-0" />
        )}
        <p className="u-body u-body--xs text-white">{message}</p>

        {link && link.type === 'url' && (
          <NextLink href={removeBaseURL(link.url)}>
            <a className="u-body u-body--xs font-bold normal-case text-primary-50 underline">
              {link.label}
            </a>
          </NextLink>
        )}

        {link && link.type === 'action' && (
          <button
            className="u-body u-body--xs font-bold normal-case text-primary-50 underline"
            onClick={() => link.action(id)}
          >
            {link.label}
          </button>
        )}
      </div>
      <button
        type="button"
        onClick={() => {
          closeToast(id);
        }}
      >
        <Close
          height={19}
          width={19}
          className="cursor-pointer"
          color="#FFFFFF"
        />
      </button>
    </div>
  );
};

export default Toast;
