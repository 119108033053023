/* eslint-disable sonarjs/max-switch-cases */
import dynamic from 'next/dynamic';

import { isProduction } from '@/lib/utils';

import type { TypeFlexibleContextGraphQL } from '@/lib/graphql/types';
import type { TypePostTypeWordpress } from '@/types';
import type { ComponentType, FunctionComponent } from 'react';

const Banner = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import('./Banner/Banner'),
  {
    ssr: true,
  },
);
const BannerEtiquetaPersonalizada = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import('./BannerEtiquetaPersonalizada/BannerEtiquetaPersonalizada'),
  {
    ssr: true,
  },
);
const PorqueComprar = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import('./PorqueComprar/PorqueComprar'),
  {
    ssr: true,
  },
);
const BloqueProductos = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import('./Productos/Productos'),
  {
    ssr: true,
  },
);
const BloqueProductosCategoria = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import('./ProductosCategory/Productos'),
  {
    ssr: true,
  },
);

const BloqueProductosMarca = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import('./ProductsByBrand/ProductsByBrand'),
  {
    ssr: true,
  },
);

const BloqueExperienciasCategoria = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import('./ExperienciasCategory/Productos'),
  {
    ssr: true,
  },
);
const BloqueCardsVideo = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import('./CardsVideo/Productos'),
  {
    ssr: true,
  },
);
const BannerStripComplete = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import('./BannerStrip/BannerStripComplete'),
  {
    ssr: true,
  },
);
const BloqueAccesorios = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import('./Accesorios/Accesorios'),
  {
    ssr: true,
  },
);
const Marcas = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import('./Marcas/Marcas'),
  {
    ssr: true,
  },
);
const BloqueExperiencias = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import('./Experiencias/Experiencias'),
  {
    ssr: true,
  },
);
const BreadCrumbs = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import('./BreadCrumbs/BreadCrumbs'),
  {
    ssr: true,
  },
);
const BloqueH = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import('./BloqueH/BloqueH'),
  {
    ssr: true,
  },
);
const Ventajas = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import('./Ventajas/Ventajas'),
  {
    ssr: true,
  },
);
const StepperNumeros = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import('./StepperNumeros/StepperNumeros'),
  {
    ssr: true,
  },
);
const ListOfCategories = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import('./ListOfCategories/ListOfCategories'),
  {
    ssr: true,
  },
);

const ListOfCategoriesImages = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import('./ListOfCategoriesImages/ListOfCategoriesImages'),
  {
    ssr: true,
  },
);

const Stepper = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import('./Stepper/Stepper').then((mod) => mod.Stepper),
  {
    ssr: true,
  },
);
const StepperSlider = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () =>
    import('./StepperSlider/StepperSlider').then((mod) => mod.StepperSlider),
  {
    ssr: true,
  },
);
const HeadBigcrafters = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () =>
    import('./HeadBigcrafters/HeadBigcrafters').then(
      (mod) => mod.HeadBigcrafters,
    ),
  {
    ssr: true,
  },
);
const TextColumns = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import('./TextColumns/TextColumns').then((mod) => mod.TextColumns),
  {
    ssr: true,
  },
);
const Faqs = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import('./Faqs/Faqs').then((mod) => mod.Faqs),
  {
    ssr: true,
  },
);
const FaqsImagen = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import('./FaqsImagen/FaqsImagen').then((mod) => mod.FaqsImagen),
  {
    ssr: true,
  },
);
const Category = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import('@/pages/category'),
  {
    ssr: true,
  },
);

const BackgroundVideoText = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () =>
    import('./BackgroundVideoText/BackgroundVideoText').then(
      (mod) => mod.BackgroundVideoText,
    ),
  {
    ssr: true,
  },
);

const RecommendsConnectif = dynamic(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  () => import('./RecommendsConnectif/RecommendsConnectif'),
  {
    ssr: true,
  },
);

const SliderCards = dynamic(() => import('./SliderCards/SliderCards'), {
  ssr: true,
});

const Votaciones = dynamic(() => import('./Votaciones/Votaciones'), {
  ssr: true,
});

const BannerUltraSlim = dynamic(
  () => import('./BannerUltraSlim/BannerUltraSlim'),
  {
    ssr: true,
  },
);

const Mosaic = dynamic(() => import('./Mosaic/Mosaic'), {
  ssr: true,
});

const SliderShadowBox = dynamic(
  () => import('./SliderShadowBox/SliderShadowBox'),
  { ssr: true },
);

type TypeFlexible = {
  flexible: any;
  typeFlexible?: TypeFlexibleContextGraphQL;
  type: TypePostTypeWordpress;
  productos?: [any];
  className?: string;
};

/**
 * FlexibleContent
 */
export const FlexibleContent: FunctionComponent<TypeFlexible> = ({
  type,
  typeFlexible = 'Contenidoflexible',
  flexible,
  productos,
  className = '',
}: TypeFlexible) =>
  flexible?.map((bloque: any, index: number) => {
    let Block: ComponentType<any> = () => null;
    let otherProps = {};

    switch (bloque?.fieldGroupName) {
      case `${type}_${typeFlexible}_Flexible_Banner`:
        Block = Banner;
        break;
      case `${type}_${typeFlexible}_Flexible_BannerEtiquetaPersonalizada`:
        Block = BannerEtiquetaPersonalizada;
        break;
      case `${type}_${typeFlexible}_Flexible_Porquecomprar`:
        Block = PorqueComprar;
        break;
      case `${type}_${typeFlexible}_Flexible_BloqueProductos`:
        Block = BloqueProductos;
        break;
      case `${type}_${typeFlexible}_Flexible_BloqueProductosCategoria`:
        Block = BloqueProductosCategoria;
        break;
      case `${type}_${typeFlexible}_Flexible_BloqueProductosMarca`:
        if (bloque.hasOwnProperty('isReady')) Block = BloqueProductosMarca;
        break;
      case `${type}_${typeFlexible}_Flexible_BloqueExperienciasCategoria`:
        Block = BloqueExperienciasCategoria;
        break;
      case `${type}_${typeFlexible}_Flexible_BloqueCardsVideo`:
        Block = BloqueCardsVideo;
        break;
      case `${type}_${typeFlexible}_Flexible_BloqueAccesorios`:
        Block = BloqueAccesorios;
        break;
      case `${type}_${typeFlexible}_Flexible_Marcas`:
        Block = Marcas;
        break;
      case `${type}_${typeFlexible}_Flexible_BloqueExperiencias`:
        Block = BloqueExperiencias;
        break;
      case `${type}_${typeFlexible}_Flexible_Breadcrumbs`:
        Block = BreadCrumbs;
        break;
      case `${type}_${typeFlexible}_Flexible_BloqueH1`:
        Block = BloqueH;
        break;
      case `${type}_${typeFlexible}_Flexible_ListOfCategories`:
        Block = ListOfCategories;
        break;
      case `${type}_${typeFlexible}_Flexible_ListOfCategoriesImages`:
        Block = ListOfCategoriesImages;
        break;
      case `${type}_${typeFlexible}_Flexible_HeadBigcrafters`:
        Block = HeadBigcrafters;
        break;
      case `${type}_${typeFlexible}_Flexible_Stepper`:
        Block = Stepper;
        break;
      case `${type}_${typeFlexible}_Flexible_StepperNumeros`:
        Block = StepperNumeros;
        break;
      case `${type}_${typeFlexible}_Flexible_StepperSlider`:
        Block = StepperSlider;
        break;
      case `${type}_${typeFlexible}_Flexible_Ventajas`:
        Block = Ventajas;
        break;
      case `${type}_${typeFlexible}_Flexible_BannerStrip`:
        Block = BannerStripComplete;
        break;
      case `${type}_${typeFlexible}_Flexible_TextColumns`:
        Block = TextColumns;
        break;
      case `${type}_${typeFlexible}_Flexible_Faqs`:
        Block = Faqs;
        break;
      case `${type}_${typeFlexible}_Flexible_FaqsImagen`:
        Block = FaqsImagen;
        break;
      case `${type}_${typeFlexible}_Flexible_BackgroundVideoText`:
        Block = BackgroundVideoText;
        break;
      case `${type}_${typeFlexible}_Flexible_GridProductBySeller`:
      case `${type}_${typeFlexible}_Flexible_GridProductByBrand`:
      case `${type}_${typeFlexible}_Flexible_GridProductByCategory`:
        // TODO: Intentar reemplazar por un componente que soporte el prop bloque y que
        // posteriormente haga el spread de las props.
        // NO SE HA HECHO ASI porque esto provoca que se quede en bucle infinito la web
        // y no sea capaz de renderizar nada.
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (bloque.hasOwnProperty('serverState')) Block = Category;
        // TODO: Es necesario pasarle los props al componente sin el prop bloque, esto se
        // debería de cambiar para mantener la interfaz
        otherProps = bloque;
        break;
      case `${type}_${typeFlexible}_Flexible_RecommendsConnectif`:
        Block = RecommendsConnectif;
        break;
      case `${type}_${typeFlexible}_Flexible_SliderCards`:
        Block = SliderCards;
        break;
      case `${type}_${typeFlexible}_Flexible_Votacion`:
        Block = Votaciones;
        break;
      case `${type}_${typeFlexible}_Flexible_BannerUltraslim`:
        Block = BannerUltraSlim;
        break;
      case `${type}_${typeFlexible}_Flexible_Mosaic`:
        Block = Mosaic;
        break;
      case `${type}_${typeFlexible}_Flexible_ShadowBoxSlider`:
        Block = SliderShadowBox;
        break;
      default:
        if (!isProduction())
          Block = () => (<div>{bloque.fieldGroupName}</div>) as JSX.Element;
        break;
    }

    return Block ? (
      <Block
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        bloque={bloque}
        id={`seccion-${index}`}
        key={index}
        productos={productos}
        className={className}
        itemIndex={index}
        {...otherProps}
      />
    ) : (
      Block
    );
  });

FlexibleContent.displayName = 'FlexibleContent';
