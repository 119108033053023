import { memo, useState } from 'react';
import dynamic from 'next/dynamic';
import { oneLine } from 'common-tags';

import { ChevronRight, Copy, Reloj } from '@/icons';
import { NextLink } from '@/atoms';
import { Button } from '@/molecules';

import { useClipboard } from '@/lib/hooks/useClipboard';

import type { FunctionComponent } from 'react';
import type { TypeSnackbarProps } from './types';

const Countdown = dynamic(() => import('@/organisms/countdown'), {
  ssr: false,
});

/**
 * Snackbar
 */
const Snackbar: FunctionComponent<TypeSnackbarProps> = ({
  type,
  message,
  mobileMessage,
  Icon,
  button,
  code,
  countdown,
  backgroundColor,
  textColor,
  className = '',
}: TypeSnackbarProps) => {
  const [copied, copy] = useClipboard(code ?? '');
  const [completed, setcompleted] = useState(false);

  if (type === 'simple') {
    return (
      <div
        className={oneLine`bg-primary-900 ${className}`}
        style={{
          color: textColor,
          backgroundColor,
        }}
      >
        <div className="flex justify-between md:justify-center">
          <div className="flex flex-row items-center gap-6 py-2 pl-2 md:py-3 md:pr-2">
            <div className="flex flex-row items-center gap-2">
              {Icon && (
                <Icon
                  height={24}
                  width={24}
                  color={textColor}
                  className="shrink-0"
                />
              )}
              {mobileMessage && (
                <>
                  <div
                    className="u-body u-body--xl max-md:hidden"
                    dangerouslySetInnerHTML={{ __html: message }}
                  />
                  <div
                    className="u-body u-body--s md:hidden"
                    dangerouslySetInnerHTML={{ __html: mobileMessage }}
                  />
                </>
              )}

              {!mobileMessage && (
                <div
                  className="u-body u-body--s md:u-body--xl"
                  dangerouslySetInnerHTML={{ __html: message }}
                />
              )}
            </div>
            {button && (
              <NextLink href={button?.link?.url ?? '/'} passHref>
                <Button
                  as="a"
                  label={button?.label ?? ''}
                  size={button?.size}
                  negative={button?.negativo}
                  style={button?.style}
                  className="!hidden md:!flex"
                />
              </NextLink>
            )}
          </div>
          {button && (
            <NextLink href={button?.link?.url ?? '/'}>
              <a
                role="button"
                className="flex w-[26px] shrink-0 bg-primary-900 md:hidden"
              >
                <ChevronRight
                  height={12}
                  width={12}
                  color="#FFFFFF"
                  className="mx-auto self-center"
                />
              </a>
            </NextLink>
          )}
        </div>
      </div>
    );
  }

  if (completed && countdown && type === 'countdown') {
    return null;
  }

  if (type === 'countdown') {
    return (
      <div
        className={oneLine`bg-primary-900 px-2 py-2 md:py-3 ${className}`}
        style={{
          color: textColor,
          backgroundColor,
        }}
      >
        <div
          className={oneLine`u-wrapper flex gap-2 md:gap-7 lg:gap-20 ${
            countdown || code ? 'justify-between' : 'justify-center'
          }`}
        >
          {mobileMessage && (
            <>
              <div
                className="u-body u-body--xs self-center md:u-body--s prose-a:font-medium prose-a:underline max-md:hidden"
                dangerouslySetInnerHTML={{ __html: message }}
              />
              <div
                className="u-body u-body--xs self-center md:u-body--s prose-a:font-medium prose-a:underline md:hidden"
                dangerouslySetInnerHTML={{ __html: mobileMessage }}
              />
            </>
          )}
          {!mobileMessage && (
            <div
              className="u-body u-body--xs self-center md:u-body--s prose-a:font-medium prose-a:underline"
              dangerouslySetInnerHTML={{ __html: message }}
            />
          )}

          {countdown || code ? (
            <div className="flex shrink-0 flex-col items-end gap-[2px] divide-[#C76913] lg:flex-row lg:items-center lg:gap-4 lg:divide-x">
              {countdown && (
                <div className="flex flex-row items-center justify-end gap-2">
                  <Reloj
                    color={textColor}
                    width={16}
                    height={16}
                    className="shrink-0"
                  />
                  <Countdown
                    date={countdown}
                    onMount={(e) => setcompleted(e.completed)}
                  />
                </div>
              )}

              {code && (
                <div
                  className="u-body u-body--xs flex cursor-pointer flex-nowrap md:u-body--s md:pl-4"
                  onClick={copy}
                >
                  {copied ? (
                    <p>¡Copiado!</p>
                  ) : (
                    <div className="flex items-center gap-[2px]">
                      <p className="whitespace-nowrap">
                        Código: <span className="font-bold">{code}</span>
                      </p>

                      <Copy
                        color={textColor}
                        width={16}
                        height={16}
                        className="shrink-0"
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : null}
        </div>
      </div>
    );
  }

  return null;
};
export default memo(Snackbar);
