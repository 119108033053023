import { useEffect, useRef, useState } from 'react';

import { Reload } from '@/icons';

import { useCart, usePreferReducedMotion } from '@/lib/hooks';

type TypeAnimationsState = {
  circleAnimation: Animation;
  numberAnimation: Animation;
};

/**
 * ToolbarCount - Displays the current number of items in the cart
 */
export const ToolbarCount = () => {
  const {
    cart: {
      contents: { itemCount },
    },
    loading,
  } = useCart();

  const [prevValue, setPrevValue] = useState(0);
  const [animations, setAnimations] = useState<TypeAnimationsState>();

  const circleRef = useRef<HTMLSpanElement>(null);
  const numberRef = useRef<HTMLSpanElement>(null);

  const motionReduced = usePreferReducedMotion();

  // TODO: Create custom hook to handle this useEffect logic
  useEffect(() => {
    const circleKeyFrames = new KeyframeEffect(
      circleRef.current,
      [
        { transform: 'scale(0)', opacity: 0, offset: 0 },
        { transform: 'scale(1.15)', opacity: 1, offset: 0.9 },
        { transform: 'scale(1)', opacity: 1, offset: 1 },
      ],
      {
        duration: !motionReduced ? 500 : 0,
        fill: 'both',
      },
    );

    const circleAnimation = new Animation(circleKeyFrames, document.timeline);

    circleAnimation.onfinish = () => {
      circleAnimation.commitStyles();
      circleAnimation.cancel();
    };

    const numberKeyFrames = new KeyframeEffect(
      numberRef.current,
      [
        { transform: 'translateY(-16px)', offset: 0 },
        { transform: 'translateY(0px)', offset: 1 },
      ],
      {
        duration: !motionReduced ? 200 : 0,
        fill: 'forwards',
        delay: !motionReduced ? 300 : 0,
      },
    );

    const numberAnimation = new Animation(numberKeyFrames, document.timeline);

    numberAnimation.onfinish = () => {
      numberAnimation.commitStyles();
      numberAnimation.cancel();
    };

    setAnimations({
      circleAnimation: circleAnimation,
      numberAnimation: numberAnimation,
    });

    return () => {
      numberAnimation.cancel();
      circleAnimation.cancel();
    };
  }, [motionReduced]);

  if (
    !loading &&
    prevValue !== itemCount &&
    circleRef.current &&
    circleRef.current.offsetParent &&
    numberRef.current &&
    numberRef.current.offsetParent &&
    animations
  ) {
    const circleAnimation = animations.circleAnimation;
    const numberAnimation = animations.numberAnimation;

    if (prevValue === 0 && itemCount > 0) {
      circleAnimation.updatePlaybackRate(1);
      circleAnimation.play();

      circleAnimation.finished.then(() => {
        numberAnimation.play();
      });
    } else if (prevValue > 0 && itemCount > 0) {
      numberRef.current.style.transform = 'translateY(-16px)';
      numberAnimation.play();
    } else if (prevValue > 0 && itemCount === 0) {
      circleAnimation.updatePlaybackRate(-1);
      circleAnimation.play();

      circleAnimation.finished.then(() => {
        if (numberRef.current) {
          numberRef.current.style.transform = 'translateY(-16px)';
        }
        numberAnimation.cancel();
      });
    }

    if (itemCount !== prevValue) {
      setPrevValue(itemCount);
    }
  }

  return (
    <>
      <span
        ref={circleRef}
        className="absolute right-0 z-10 -ml-2 flex h-6 w-6 scale-0 items-center justify-center overflow-hidden rounded-full border-4 border-secondary-500 bg-secondary-500"
      >
        <span
          ref={numberRef}
          className="-translate-y-4 transform font-mono text-sm font-medium text-white"
        >
          {loading ? (
            <Reload width={15} className="animate-spin motion-reduce:hidden" />
          ) : (
            itemCount
          )}
        </span>
      </span>

      <span className="sr-only">
        {loading ? 'cargando' : itemCount} productos en el carrito
      </span>
    </>
  );
};
