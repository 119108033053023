import { useContext } from 'react';
import dynamic from 'next/dynamic';
import Image from 'next/future/image';

import * as Icons from '@/icons';
import { Container, NextLink } from '@/atoms';
import { IconButton, Prose } from '@/molecules';
import { FlexibleContent } from '@/templates';

import { InitPropsProvider } from '@/components/providers/initPropsProvider';
import { imageTransformer } from '@/lib/graphql/transformers';
import { removeBaseURL } from '@/lib/utils';

import type { TypeImageGraphQL } from '@/lib/graphql/types';

const BeerSubmarine = dynamic(
  () => import('@/assets/images/beer-submarine.svg'),
  { ssr: false },
);

const getMenu = (menu: any) => menu?.edges[0]?.node;

// TODO tipar los datos
export const Footer = () => {
  const propsProvider = useContext(InitPropsProvider);
  const menus = propsProvider?.menus;
  const legal = getMenu(menus?.legal) ?? {};
  const col1 = getMenu(menus?.recommended) ?? {};
  const col2 = getMenu(menus?.about) ?? {};
  const col3 = getMenu(menus?.support) ?? {};
  const col4 = getMenu(menus?.extra) ?? {};
  const bcOptions = propsProvider?.options?.ajustesGenerales ?? {};
  const copyright = bcOptions?.copyright;
  const payments = bcOptions?.payments ?? [];
  const socialNetworks = bcOptions?.socialNetworks ?? [];
  const newsletter = bcOptions?.newsletter;
  const flexibleContent = bcOptions?.footerContent ?? [];
  const legalText = bcOptions?.legalText;
  const logo = bcOptions?.logo as TypeImageGraphQL;

  return (
    <footer aria-labelledby="footer-heading">
      <p id="footer-heading" className="sr-only">
        Footer
      </p>

      <Container wrapper>
        <div className="grid grid-cols-2 gap-x-8 gap-y-6 md:grid-cols-3 lg:grid-cols-[375px_repeat(4,_1fr)] lg:gap-y-4">
          <div className="col-span-2 md:order-4 md:col-span-1 lg:order-1">
            <div className="mb-5 max-w-[375px] lg:mb-7">
              <Image {...imageTransformer(logo)} alt="Logo Bigcrafters" />
            </div>
          </div>

          <div className="col-span-2 md:order-1 md:col-span-2 md:row-span-3 lg:order-2 lg:col-span-1 lg:row-span-1 lg:row-start-2">
            <div className="grid max-w-[500px] grid-cols-3 gap-x-5 gap-y-4">
              <BeerSubmarine
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                className="mx-auto max-w-[100px] lg:row-span-2"
              />

              <div className="col-span-2 flex flex-col justify-center gap-4">
                {newsletter?.tituloNewsletter && (
                  <span className="u-subtitle u-subtitle@mobile--l">
                    {newsletter?.tituloNewsletter}
                  </span>
                )}

                {newsletter?.textoNewsletter && (
                  <p className="u-body u-body--s text-typo-secondary">
                    {newsletter?.textoNewsletter}
                  </p>
                )}
              </div>

              <div className="col-span-full lg:col-span-2">
                <form
                  onSubmit={(e) => e.preventDefault()}
                  id="newsletter_form"
                  className="w-full"
                  name="newsletter_form"
                  method="POST"
                >
                  <label className="relative block w-full">
                    <input
                      id="newsletter_email"
                      type="email"
                      placeholder="Tu correo electrónico"
                      className="w-full p-4 pr-16"
                    />

                    <IconButton
                      type="submit"
                      size="small"
                      className="button-lightbox-popin absolute right-4 top-1/2 -translate-y-1/2"
                      aria-label="Enviar"
                    >
                      <Icons.ArrowRight width={20} height={14} />
                    </IconButton>
                  </label>
                </form>
              </div>
            </div>
          </div>

          <div className="col-span-2 flex flex-row flex-wrap gap-6 md:order-2 md:col-span-1 lg:order-6 lg:col-start-3">
            {// TODO: Añadir tipos
            payments?.map((item: any) => {
              const Icon = Icons[item.icon as keyof typeof Icons];

              return Icon ? (
                <div key={item.icon}>
                  <span className="sr-only">{item.icon}</span>
                  <Icon aria-hidden="true" />
                </div>
              ) : null;
            })}
          </div>

          <div className="col-span-2 flex flex-row gap-6 md:order-3 md:col-span-1 lg:order-7">
            {// TODO: Añadir tipos
            socialNetworks?.map((item: any) => {
              const Icon = Icons[item.icon as keyof typeof Icons];

              return Icon ? (
                <a key={item.icon} href={item.link}>
                  <div>
                    <span className="sr-only">{item.icon}</span>
                    <Icon className="h-6 w-6" aria-hidden="true" />
                  </div>
                </a>
              ) : null;
            })}
          </div>

          <div className="col-span-2 flex flex-col gap-4 xs:col-span-1 md:order-5 lg:order-3 lg:row-span-2 lg:gap-6">
            {col1?.name && (
              <span className="u-subtitle u-subtitle@mobile--l">
                {col1?.name}
              </span>
            )}

            {col1?.menuItems && (
              <ul role="list" className="flex flex-col gap-4 lg:gap-6">
                {// TODO: Añadir tipos
                col1?.menuItems?.nodes?.map((item: any, index: number) => (
                  <li key={index}>
                    <NextLink href={removeBaseURL(item.url)}>
                      <a className="u-actions u-actions--link">{item.label}</a>
                    </NextLink>
                  </li>
                ))}
              </ul>
            )}
          </div>

          <div className="col-span-2 flex flex-col gap-4 xs:col-span-1 md:order-6 lg:order-4 lg:row-span-2 lg:gap-6">
            {col2?.name && (
              <span className="u-subtitle u-subtitle@mobile--l">
                {col2?.name}
              </span>
            )}

            {col2?.menuItems && (
              <ul role="list" className="flex flex-col gap-4 lg:gap-6">
                {// TODO: Añadir tipos
                col2?.menuItems?.nodes?.map((item: any, index: number) => (
                  <li key={index}>
                    <NextLink href={removeBaseURL(item.url)}>
                      <a className="u-actions u-actions--link">{item.label}</a>
                    </NextLink>
                  </li>
                ))}
              </ul>
            )}
          </div>

          <div className="col-span-2 flex flex-col gap-4 xs:col-span-1 md:order-7 lg:order-5 lg:row-span-2 lg:gap-6">
            {col3?.name && (
              <span className="u-subtitle u-subtitle@mobile--l">
                {col3?.name}
              </span>
            )}

            {col3?.menuItems && (
              <ul role="list" className="flex flex-col gap-4 lg:gap-6">
                {// TODO: Añadir tipos
                col3?.menuItems?.nodes?.map((item: any, index: number) => (
                  <li key={index}>
                    <NextLink href={removeBaseURL(item.url)}>
                      <a className="u-actions u-actions--link">{item.label}</a>
                    </NextLink>
                  </li>
                ))}
              </ul>
            )}
          </div>

          <div className="col-span-2 flex flex-col gap-4 xs:col-span-1 md:order-7 lg:order-5 lg:row-span-2 lg:gap-6">
            {col4?.name && (
              <span className="u-subtitle u-subtitle@mobile--l">
                {col4?.name}
              </span>
            )}

            {col4?.menuItems && (
              <ul role="list" className="flex flex-col gap-4 lg:gap-6">
                {// TODO: Añadir tipos
                col4?.menuItems?.nodes?.map((item: any, index: number) => (
                  <li key={index}>
                    <NextLink href={removeBaseURL(item.url)}>
                      <a className="u-actions u-actions--link">{item.label}</a>
                    </NextLink>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
        {legalText && (
          <Prose as="div" className="u-wrapper pt-5" html={legalText} />
        )}
      </Container>

      {flexibleContent && (
        <Container
          wrapper
          backgroundColor="bg-bg-tertiary"
          padding="p-0"
          className="text-typo-alternative"
        >
          <FlexibleContent
            type="BcGeneralSettings"
            typeFlexible="Ajustesgenerales"
            flexible={flexibleContent}
          />
        </Container>
      )}

      <Container
        wrapper
        padding="px-4 py-4"
        backgroundColor="bg-bg-tertiary"
        className="text-typo-alternative"
      >
        <div className="u-actions u-actions--link flex flex-col justify-center gap-10 text-center lg:flex-row lg:justify-between lg:text-left">
          <ul role="list" className="flex flex-col gap-4 lg:flex-row lg:gap-12">
            {// TODO: Añadir tipos
            legal?.menuItems?.nodes?.map((item: any, index: number) => (
              <li key={index}>
                <NextLink href={removeBaseURL(item.url)}>
                  <a>{item.label}</a>
                </NextLink>
              </li>
            ))}
          </ul>

          {copyright}
        </div>
      </Container>
      <Container
        wrapper
        padding="px-4 py-4"
        backgroundColor="bg-bg-tertiary"
        className="border-t border-primary-600 text-typo-alternative"
      >
        <div className="u-body u-body--s flex flex-col justify-center gap-10 text-center text-primary-400 lg:flex-row lg:justify-between lg:text-left">
          Estrella Galicia recomienda el consumo responsable. Prohibida la venta
          o suministro de bebidas alcohólicas a menores de 18 años.
        </div>
      </Container>
    </footer>
  );
};
