import { useCallback, useEffect, useRef, useState } from 'react';

/**
 * Hook to copy text to clipboard.
 * @param  {string} str String to copy
 * @return {[boolean, () => void]} [copied, copyAction]
 */
export const useClipboard = (
  str: string,
  timeout = 2000,
): [boolean, () => void] => {
  const copyableString = useRef(str);
  const [copied, setCopied] = useState(false);
  const copyAction = useCallback(async () => {
    navigator?.clipboard
      ?.writeText(copyableString.current)
      .then(() => setCopied(true))
      .catch(() => setCopied(false))
      .finally(() => setTimeout(() => setCopied(false), timeout));
  }, [copyableString]);

  useEffect(() => {
    copyableString.current = str;
  }, [str]);

  return [copied, copyAction];
};
