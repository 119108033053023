import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { oneLine } from 'common-tags';

import { CloseIcon } from '@/icons';

import { useFixSafariPortal, useRouteChangeStart } from '@/lib/hooks';

import type { ModalProps } from './types';

/**
 * Primary UI component for user interaction
 */
export const Modal: React.FC<ModalProps> = ({
  open = false,
  setOpen,
  children,
  background = 'bg-bg-secondary',
  /* eslint-disable-next-line @typescript-eslint/no-empty-function */
  onClose = () => {},
  noMaxWidthDesktop = false,
  unmount = false,
  maxWidth,
}) => {
  const close = () => {
    onClose();
    setOpen(false);
  };

  if (typeof window !== 'undefined') {
    setTimeout(() => {
      const elements: NodeListOf<any> = document.querySelectorAll(
        '[id^=headlessui-dialog]',
      );
      elements.forEach((el) => {
        el.scrollTop = 0;
      });
    }, 200);
  }

  useFixSafariPortal(open);
  useRouteChangeStart(close);

  return (
    <Transition.Root show={open} as={Fragment} unmount={unmount}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={onClose}
        unmount={unmount}
        open={open}
      >
        <div className="flex min-h-screen items-center justify-center px-4 py-4 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            unmount={unmount}
          >
            <Dialog.Overlay
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              onClick={close}
            />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            unmount={unmount}
          >
            <div
              className={oneLine`
                ${background}
                ${
                  noMaxWidthDesktop
                    ? 'sm:max-w-max'
                    : !maxWidth
                      ? 'sm:max-w-lg'
                      : ''
                }
                ${maxWidth ?? ''}
                relative inline-block w-full transform overflow-hidden text-left align-bottom shadow-xl transition-all sm:my-8 sm:align-middle
              `}
            >
              <div className={`${background} p-6 sm:p-8`}>
                <div
                  onClick={close}
                  className="absolute right-4 top-4 z-10 cursor-pointer"
                >
                  <CloseIcon />
                </div>

                <div className="sm:flex sm:items-start">
                  <div className="flex min-h-full w-full items-center justify-center pt-12">
                    <div
                      className={oneLine`
                        w-full 
                        ${noMaxWidthDesktop ? 'lg:max-w-none' : ''}
                        ${maxWidth ? maxWidth : 'max-w-md'}
                      `}
                    >
                      {children}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
