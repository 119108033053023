import React, { useContext, useRef } from 'react';
import Image from 'next/future/image';
import { useRouter } from 'next/router';

import { NextLink } from '@/atoms';
import {
  DesktopMenu,
  MobileMenu,
  Toolbar,
  ToolbarCount,
} from '@/molecules/primarymenu';

import { FacadeSearchBoxDesktop } from '@/components/algolia';
import { InitPropsProvider } from '@/components/providers';
import { imageTransformer } from '@/lib/graphql/transformers';
import { useHeaderSticky, useIntersectionObserver } from '@/lib/hooks';
import { routes } from '@/lib/routes';
import { isCartRoute, isMyAccountRoute } from '@/lib/utils';
import ToastCenter from '../toast-center';
import CintaDesktop from '/public/assets/images/Bigcrafters-cinta-impulsado-eg-desktop.png';
import CintaMobile from '/public/assets/images/Bigcrafters-cinta-impulsado-eg-mobile.png';

import type { TypeImageGraphQL } from '@/lib/graphql/types';

export const Header = () => {
  const router = useRouter();
  const withOutSticky =
    isCartRoute(router.pathname) || isMyAccountRoute(router.pathname);

  const {
    options: { ajustesGenerales },
  } = useContext(InitPropsProvider);

  const logoMobile = imageTransformer(
    ajustesGenerales.logoMobile as TypeImageGraphQL,
  );

  const logoDesktop = imageTransformer(
    ajustesGenerales.logo as TypeImageGraphQL,
  );

  const headerRef = useRef<HTMLElement>(null);
  const cintaRef = useRef<HTMLDivElement>(null);

  const entry = useIntersectionObserver(headerRef, {
    root: null,
    rootMargin: '-0.1px',
    threshold: 0,
  });

  useHeaderSticky({
    headerRef: headerRef,
    cintaRef: cintaRef,
    delay: 600,
    threshold: 70,
    withOutSticky,
  });

  return (
    <header
      ref={headerRef}
      className={`${withOutSticky ? '' : 'sticky'} top-0 z-40 transition-all`}
    >
      <div className="relative top-0 flex flex-col gap-2 border-b border-b-primary-100 bg-bg-primary px-4 py-1.5 lg:py-3">
        <div className="flex flex-col lg:u-wrapper">
          <div className="relative flex flex-row items-center justify-between max-lg:h-11 lg:items-end lg:gap-5">
            <div className="flex flex-row items-center gap-6 lg:hidden">
              <MobileMenu />
              {logoMobile && (
                <NextLink href={routes.index}>
                  <a aria-label="Ir a la home" className="relative h-11 w-36">
                    <Image
                      src={logoMobile.src}
                      alt={logoMobile?.alt ?? 'Logo Header Mobile'}
                      fill
                      sizes="145px"
                      draggable={false}
                      className="object-contain object-center"
                      priority
                    />
                  </a>
                </NextLink>
              )}
            </div>

            {/* TODO: Doesn't open up on the first click if the scroll is mid-page */}
            <FacadeSearchBoxDesktop
              className="w-full max-lg:hidden"
              placeholder="Busca productos aquí ..."
            />

            {logoDesktop && (
              <NextLink href={routes.index}>
                <a
                  aria-label="Ir a la home"
                  className="relative h-[53px] w-full max-w-[196px] max-lg:hidden"
                >
                  <Image
                    src={logoDesktop.src}
                    alt={logoDesktop?.alt ?? 'Logo Header Desktop'}
                    fill
                    draggable={false}
                    sizes="200px"
                    className="object-contain object-center"
                    priority
                  />
                </a>
              </NextLink>
            )}

            <Toolbar className="lg:w-full lg:border-b lg:border-b-primary-200 lg:pb-4">
              <ToolbarCount />
            </Toolbar>
          </div>

          <div ref={cintaRef}>
            <Image
              src={CintaMobile}
              alt="Cinta Bigcrafters Mobile"
              draggable={false}
              priority
              quality={100}
              className="mx-auto w-full max-w-md lg:hidden"
            />

            <Image
              src={CintaDesktop}
              alt="Cinta Bigcrafters Desktop"
              draggable={false}
              priority
              quality={100}
              className="mx-auto max-lg:hidden"
            />
          </div>
        </div>
        <DesktopMenu />
      </div>
      <ToastCenter
        {...(withOutSticky &&
          entry?.intersectionRatio === 0 && {
            style: {
              zIndex: -10,
              width: '100%',
              inset: 'auto',
              top: 0,
              position: 'fixed',
            },
          })}
      />
    </header>
  );
};
