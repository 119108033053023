import type { FunctionComponent } from 'react';
import type { TypeCartShippingHeaderProps } from './types';

/**
 * CartShippingHeader
 */
export const CartShippingHeader: FunctionComponent<
  TypeCartShippingHeaderProps
> = ({ className = '', title, description }: TypeCartShippingHeaderProps) => (
  <div
    className={`u-body bg-primary-100 px-3 py-2 text-primary-900 outline outline-1 outline-primary-100 md:p-4 ${className}`}
  >
    <p className="u-body--s font-bold">{title}</p>

    {description && <p className="u-body--xs mt-1">{description}</p>}
  </div>
);

CartShippingHeader.displayName = 'CartShippingHeader';
